<template>
  <div id="page-footer">
    <b-card bg-variant="info" text-variant="white" class="text-center" fluid>
      <b-card-text
        >
       聚客宝导航站 V-0.3.1 &copy; 2020-2025
        <br />
        </b-card-text
      >
    </b-card>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  components: {},
};
</script>