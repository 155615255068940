<template>
  <div id="admin-site">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          :title="title"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <b-container fluid="sm">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="网站"
                :rules="{ required: true, min: 2 }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">
                    <label>网站</label>
                  </b-col>
                  <b-col md="9">
                    <b-input
                      v-model="site"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入网站"
                    ></b-input>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

              <validation-provider
                name="网址"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">网址</b-col>
                  <b-col md="9">
                    <b-input
                      v-model="url"
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="输入标题"
                    ></b-input>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>

              <validation-provider
                name="推荐"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <b-row class="mb-2">
                  <b-col md="3">推荐</b-col>
                  <b-col md="9" class="text-left">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="isHot"
                      name="radio-sub-component"
                    >
                      <b-form-radio value="1">是</b-form-radio>
                      <b-form-radio value="0">否</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
              <b-button type="submit" variant="primary">添加网站</b-button>
            </b-form>
          </validation-observer>
        </b-container>
      </template>
    </admin-template>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";

export default {
  name: "SiteAdd",
  data() {
    return {
      title: "网址添加",
      linksArray: [
        {
          title: "网站管理",
          url: "/",
        },
      ],
      returnUrl: null,
      username: null,
      token: null,
      itemid: null,
      item: null,
      site: "",
      url: "",
      isHot: "0",
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
  },
  created() {
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");

    this.itemid = this.$route.query.itemid;
    this.item = this.$route.query.item;

    this.title = this.item + "-" + this.title;
    this.returnUrl = "/site/" + this.itemid + "/" + this.item;
  },
  methods: {
    onSubmit() {
      this.$axios
        .post("/api/v20/site/add/save/" + this.username + "/" + this.token, {
          itemid: this.itemid,
          site: this.site,
          url: this.url,
          isHot: this.isHot,
        })
        .then((response) => {
          let data = response.data;

          if (data.code === 1) {
            this.site = "";
            this.url = "";
            this.isHot = "";
            this.$bvModal.msgBoxOk(data.message)
          } else {
            this.$bvModal.msgBoxOk(data.message)
          }
        })
        .catch((error) => {
          // this.$message.error(data.message);
        });
    },
  },
};
</script>