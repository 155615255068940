<template>
  <div id="admin-item">
    <admin-template>
      <template slot="admin-content">
        <admin-navbar
          title="网站类型列表"
          :linksArray="linksArray"
          :returnUrl="returnUrl"
        ></admin-navbar>
        <!-- 列表 -->
        <b-container>
          <b-table striped hover :items="itemsArray" :fields="fields">
            <template v-slot:head(action)>
              <div class="">
                <b-link @click="handleAdd"
                  ><b-icon
                    icon="plus"
                    class="border border-info rounded"
                    variant="info"
                  ></b-icon
                ></b-link>
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <b-link @click="handleEdit(data.item)" class="mr-3"
                ><b-icon icon="pen"></b-icon
              ></b-link>
              <b-link @click="handleDelete(data.item.itemid)"
                ><b-icon icon="trash"></b-icon
              ></b-link>
            </template>
          </b-table>
        </b-container>
      </template>
    </admin-template>

    <!-- <b-modal id="modal-1" title="BootstrapVue" body-bg-variant="primary" hide-header hide-footer>
      <p class="my-4">{{ message }}</p>
    </b-modal> -->
    <!-- <message :message="message" :msgBg="msgBg" :msgColor="msgColor"></message> -->
    <Message message="hello" alert="success"></Message>
  </div>
</template>

<script>
import AdminTemplate from "../components/AdminTemplate";
import AdminNavbar from "../components/AdminNavbar";
import Message from "../components/Message.vue";

export default {
  name: "Item",
  data() {
    return {
      title: "网站类型列表",
      linksArray: [],
      returnUrl: "/",
      username: "",
      token: "",
      fields: [
        {
          key: "slot",
          label: "序号",
        },
        {
          key: "item",
          label: "分类名称",
        },
        {
          key: "action",
          label: "维护操作",
        },
      ],
      itemsArray: [],
      message: null,
      msgBg: null,
      msgColor: null,
    };
  },
  components: {
    AdminTemplate,
    AdminNavbar,
    Message,
  },
  methods: {
    handleAdd: function () {
      this.$router.push("/item/add");
    },
    handleEdit: function (row) {
      this.$router.push(
        "/item/edit?item=" + encodeURIComponent(JSON.stringify(row))
      );
    },
    handleDelete: function (itemid) {
      //连接服务器端，删除数据
      this.$axios
        .post("/api/v20/site/item/remove/" + this.username + "/" + this.token, {
          itemid: itemid,
        })
        .then((response) => {
          let data = response.data;
          
          switch (data.code) {
            case 0:
              window.sessionStorage.setItem("token", null);
              this.$router.push("/login");
              break;

            case 1:    
              //更新数据
              this.getData();
              // 弹窗信息
              this.$bvModel.msgBoxOk(data.message);
              // 刷新页面
              this.$router.go(0);
              break;

            default:
              this.$bvModel.msgBoxOk(data.message);
              break;
          }
        })
        .catch((error) => {
          this.$bvModel.msgBoxOk('程序执行异常');
          // console.log(error);
          // this.$message.error(error);
        });
    },
    getData: function () {
      this.$axios
        .get("/api/v20/site/item/" + this.username + "/" + this.token)
        .then((response) => {
         
          let data = response.data;

          if (data.code === 1) {
            this.itemsArray = data.data;
            // this.tableData = data.data;
            // console.log(JSON.stringify(itemsArray));
            // this.$message.success(data.message);
          } else {
            this.itemsArray = [];
            // window.sessionStorage.setItem("token", null);
            // this.$router.push("/login");
            // this.$message.error(data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // this.$message.error(data.message);
        });
    },
  },
  created: function () {
    //从服务器获取数据
    this.username = window.sessionStorage.getItem("username");
    this.token = window.sessionStorage.getItem("token");
    this.getData();
    // this.$bvModal.msgBoxOk('hello ariel', {
    //   okVariant: 'danger',
    // });
    
    // okVariant: 'success',
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      console.log("Modal is about to be shown", bvEvent, modalId);
    });
  },
};
</script>